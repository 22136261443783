import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

// Layout component
import Layout from '../components/layouts/Layout';
import BannerPicture from '../components/BannerPicture';
import LargeHorizontalCard from '../components/LargeHorizontalCard';
import SectionOnlyFooter from '../components/SectionOnlyFooter';
import FeaturesCard from '../components/FeaturesCard';
import TwoColumnsPicture from '../components/TwoColumnsPicture';

const TwoColumnsLayout = styled.div``;

const LargeHorizontalCardLayout = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    margin: 7% 0%;

    @media (min-width: 320px) and (max-width: 480px) {
        flex-direction:column;
        width:100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        width:100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        width:100%;
    }
`;

const LargeHorizontalCardTitle = styled.h1`
    font-size: 30px;
    font-weight: 200;

    @media (min-width: 320px) and (max-width: 1024px) {
        font-size: 24px;
        font-weight: 200;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        font-size: 24px;
        font-weight: 200;
    }
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('productsPage');
    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_PRODUCT')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_PRODUCT')}/>
            </Helmet>
            <Layout>
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_BEST_EXPERIENCE')}
                        cellParagraph={t('DESCRIPTION_PLATFORM_WEMAP')}
                        pictureRight={data.map.childImageSharp}
                        playButtonUrl="/images/home/b2b_video.mp4"
                        altDescription='mobile view of ar navigation and computer view of map'
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        urlFooter='/contact/'
                        phraseLogo={t('TITRE_LOGO_INDUSTRY')}
                        customersLogos={[
                            {
                                url: '/travel/',
                                image: '/images/products/wemap-web/air-france.svg'
                            },
                            {
                                url: '/medias/',
                                image: '/images/products/logo-radio-france.svg'
                            },
                            {
                                url: '/government/',
                                image: '/images/products/games/crt-ile-de-france.png'
                            },
                            {
                                url: '/sport/',
                                image: '/images/industries/sport/vendee-globe.svg'
                            },
                            {
                                url: '/games/',
                                image: '/images/products/games/logo_uh.png'
                            }
                        ]}
                    />

                    <TwoColumnsLayout>
                        <TwoColumnsPicture title={t('TITLE_BASE_MAP')}
                            text={t('DESCRIPTION_BEAUTIFUL_DESIGN')}
                            titleButton={t('BUTTON_CARD_LEARN_MORE')}
                            linkUrl='https://blog.getwemap.com/personnalisez-vos-fonds-de-carte-25bcf2387ba8'
                            pictureObject={data.basemap.childImageSharp}
                            altDescription='3 pictures of base map styles'
                        />

                        <TwoColumnsPicture title={t('TITLE_INDOOR_MAP')}
                            text={t('DESCRIPTION_INDOOR_SPACES')}
                            titleButton={t('BUTTON_CARD_MORE_INFO')}
                            linkUrl='/indoor-maps/'
                            pictureObject={data.indoormap.childImageSharp}
                            altDescription='3 pictures of indoor maps'
                            inverse
                        />

                        <TwoColumnsPicture title={t('TITLE_MARKER')}
                            text={t('DESCRIPTION_OVERLAY_MARKERS')}
                            titleButton={t('BUTTON_CARD_LEARN_MORE')}
                            linkUrl='/contact/'
                            pictureObject={data.markermap.childImageSharp}
                            altDescription='a map with 3 pinpoints'
                        />

                        <TwoColumnsPicture title={t('TITLE_POLYLIGNE')}
                            text={t('DESCRIPTION_POLYLINE')}
                            titleButton={t('BUTTON_CARD_EXPLORE')}
                            linkUrl='https://blog.getwemap.com/les-parcours-riches-arrivent-dans-wemap-eef9dd6d9be2'
                            pictureObject={data.polyligne.childImageSharp}
                            altDescription='a video of a map with shapes and layers'
                            inverse
                        />

                        <TwoColumnsPicture title={t('TITLE_SHAPES_LAYERS')}
                            text={t('DESCRIPTION_SHAPES_LAYERS')}
                            titleButton={t('BUTTON_CARD_LEARN_MORE')}
                            linkUrl='https://blog.getwemap.com/interactive-data-layers-with-wemap-9bfff404f6b4'
                            video='/images/products/shapes-layers-v2.mov'
                            altDescription='a video of a map with shapes and layers'
                        />

                        <TwoColumnsPicture title={t('TITLE_SEARCH')}
                            text={t('DESCRIPTION_SEARCH_TOOLS')}
                            titleButton={t('BUTTON_CARD_DISCOVER')}
                            linkUrl='https://blog.getwemap.com/nouvelle-version-du-moteur-de-recherche-wemap-3deb9bd86ef1'
                            video='/images/products/search-wemap-official-v4.mp4'
                            altDescription='a video of a map showing the search function'
                            inverse
                        />

                        <TwoColumnsPicture title={t('TITLE_NAVIGATION')}
                            text={t('DESCRIPTION_STEP_BY_STEP_NAV')}
                            titleButton={t('BUTTON_CARD_MORE_INFORMATION')}
                            linkUrl='/navigation-wayfinding/'
                            pictureObject={data.navigation.childImageSharp}
                            altDescription='3 step by step pictures of navigation'
                        />

                        <TwoColumnsPicture title={t('TITLE_POSITIONING')}
                            text={t('DESCRIPTION_BLUE_DOT')}
                            titleButton={t('BUTTON_CARD_LEARN_MORE')}
                            linkUrl='https://blog.getwemap.com/relocalisation-par-vision-computer-vision-134612359fba'
                            pictureObject={data.positioning.childImageSharp}
                            altDescription='3 pictures of scanning the environment for positionning'
                            inverse
                        />
                    </TwoColumnsLayout>

                    <hr className='desktop' />
                    <LargeHorizontalCardTitle>{t('TITLE_CARD_WEMAP_BUILD_TOOLS')}</LargeHorizontalCardTitle>

                    <LargeHorizontalCardLayout>
                        <LargeHorizontalCard
                            alt='Representation of wemap pro'
                            headerImageUrl='/images/products/icon_products_wemappro.svg'
                            title={t('TITLE_CARD_WEMAP_PRO')}
                            text={t('DESCRIPTION_CARD_WEMAP_PRO')}
                            textButton={t('BUTTON_CARD_WEMAP_PRO')}
                            url='/wemap-pro/' />

                        <LargeHorizontalCard
                            alt='Representation of documentation'
                            headerImageUrl='/images/products/icon_products_developers.svg'
                            title={t('TITLE_CARD_WEMAP_DEVELOPERS')}
                            text={t('DESCRIPTION_CARD_WEMAP_DEVELOPERS')}
                            textButton={t('BUTTON_CARD_WEMAP_DEVELOPERS')}
                            url='/developers/' />
                    </LargeHorizontalCardLayout>

                    <TwoColumnsLayout>
                        <TwoColumnsPicture title={t('TITLE_ONLINE_MAP_MAKER')}
                            text={t('DESCRIPTION_MANAGE')}
                            titleButton={t('BUTTON_BOOK_A_DEMO')}
                            linkUrl='/contact/'
                            video='/images/products/map-maker-v4.mp4'
                            altDescription='a video of the Wemap map creator platform'
                        />
                    </TwoColumnsLayout>

                    <FeaturesCard
                        title={t('TITLE_WEMAP_COVERED')}
                        text={t('DESCRIPTION_WEMAP_COVERED')}
                        color='#2FAAE1'
                        altImageFooter='mobiles with open Wemap pinpoint'
                        imgFooter={data.pointLivemap.childImageSharp}
                        infosCards={[
                            {
                                title: t('TITLE_CARD_EXPERIENCES'),
                                text: t('DESCRIPTION_CARD_EXPERIENCES'),
                                buttonUrl: '/contact/',
                                firstImageUrl: '/images/products/icon_wemapfast.svg',
                                secondImageUrl: '/images/products/icon_wemapwebmobile.svg',
                                thirdImageUrl: '/images/products/icon_wemapfeature.svg',
                                fourthImageUrl: '/images/home/icon_experience-seemore.svg',
                                fifthImageUrl: '/images/home/arrow.svg',
                                firstTextIcon: t('TITLE_ICON_EXPERIENCE_WEMAP_FAST'),
                                secondTextIcon: t('TITLE_ICON_EXPERIENCE_WEMAP_RESPONSIVE'),
                                thirdTextIcon: t('TITLE_ICON_EXPERIENCE_WEMAP_FEATURE'),
                                fourthTextIcon: t('TITLE_ICON_LEARN_MORE'),
                                color: '#2FAAE1'
                            },
                            {
                                title: t('TITLE_CARD_TOOLS'),
                                text: t('DESCRIPTION_CARD_TOOLS'),
                                buttonUrl: '/contact/',
                                firstImageUrl: '/images/home/icon_wemapengine.svg',
                                secondImageUrl: '/images/home/icon_wemappro.svg',
                                thirdImageUrl: '/images/home/icon_documentation.svg',
                                fourthImageUrl: '/images/home/icon_tools-seemore.svg',
                                fifthImageUrl: '/images/home/arrow.svg',
                                firstTextIcon: t('TITLE_ICON_TOOLS_WEMAP_ENGINE'),
                                secondTextIcon: t('TITLE_ICON_TOOLS_WEMAP_PRO'),
                                thirdTextIcon: t('TITLE_ICON_TOOLS_WEMAP_DOC'),
                                fourthTextIcon: t('TITLE_ICON_LEARN_MORE'),
                                color: '#FFC871'
                            },
                            {
                                title: t('TITLE_CARD_SERVICE'),
                                text: t('DESCRIPTION_CARD_SERVICE'),
                                buttonUrl: '/contact/',
                                firstImageUrl: '/images/products/icon_wemaponboarding.svg',
                                secondImageUrl: '/images/products/icon_wemapanalytics.svg',
                                thirdImageUrl: '/images/products/icon_wemapsla.svg',
                                fourthImageUrl: '/images/home/icon_app-seemore.svg',
                                fifthImageUrl: '/images/home/arrow.svg',
                                firstTextIcon: t('TITLE_ICON_SERVICE_ONBOARDING'),
                                secondTextIcon: t('TITLE_ICON_SERVICE_ANALYTICS'),
                                thirdTextIcon: t('TITLE_ICON_SERVICE_SLA'),
                                fourthTextIcon: t('TITLE_ICON_LEARN_MORE'),
                                color: '#FF9ACC'
                            }
                        ]}
                    />

                    <BannerPicture
                        backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with computer of Wemap map'
                        titleBannerPicture={t('TITLE_BANNER_INTERACTIVE_MAP')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/'
                    />
                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  banner: file(relativePath: {eq: "industries/banner-picture-industries.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  basemap: file(relativePath: {eq: "products/basemap.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  indoormap: file(relativePath: {eq: "products/indoormap.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  polyligne: file(relativePath: {eq: "products/polyligne-v2.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  markermap: file(relativePath: {eq: "products/poi.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  navigation: file(relativePath: {eq: "products/navigation-map.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  positioning: file(relativePath: {eq: "products/positioning.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  map: file(relativePath: {eq: "products/nav-livemap-v2.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 540, layout: FIXED)
    }
  }
  pointLivemap: file(relativePath: {eq: "products/laptop_analytics.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
}
`;
